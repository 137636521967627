export default {
  init() {
    $('.menuBtn').click(function() {
      event.preventDefault();
      $(this).toggleClass('act');

      if($(this).hasClass('act')) {
        $('.nav-primary').addClass('act');
      } else {
        $('.nav-primary').removeClass('act');
      }
    });
    
    $('#menu-primary li a').click(function() {
      $('.nav-primary').removeClass('act');
      $('.menuBtn').removeClass('act');
    });

    $('#audio-control').click(function(){
      if( $('#homevideo').prop('muted') ) {
        $('#homevideo').prop('muted', false);
        $('.home .fa-volume').removeClass('fa-volume').addClass('fa-volume-xmark');
      } else {
        $('#homevideo').prop('muted', true);
        $('.home .fa-volume-xmark').removeClass('fa-volume-xmark').addClass('fa-volume');
      }
    });

    $('#full-screen').on('click', function() {
      var click = $(this).data('clicks');
      if (click) {
        $('.video-container').addClass('fullwidth');
        $('#full-screen i').removeClass('fa-maximize').addClass('fa-minimize');
        $('#homevideo').prop('muted', false);
        $('.home .fa-volume').removeClass('fa-volume').addClass('fa-volume-xmark');
      } else {
        $('.video-container').removeClass('fullwidth');
        $('#full-screen i').removeClass('fa-minimize').addClass('fa-maximize');
        $('#homevideo').prop('muted', true);
        $('.home .fa-volume-xmark').removeClass('fa-volume-xmark').addClass('fa-volume');
      }
      $(this).data('clicks', !click);
    })
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
